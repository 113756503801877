import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import HeaderView from "../component/HeaderView";
import FooterView from "../component/FooterView";
import TechnologyImage from "../assets/technology.png";
import { ReactComponent as AgilityImage } from "../assets/agility.svg";
import { ReactComponent as BoostingImage } from "../assets/boosting.svg";
import { ReactComponent as ComplianceImage } from "../assets/compliance.svg";
import { ReactComponent as SecurityImage } from "../assets/security.svg";
import { ReactComponent as AdvantageImage } from "../assets/advantage.svg";
import { ReactComponent as RealTimeDataImage } from "../assets/realtime-processing.svg";
import { Path as RegistrationPath } from "../../registration/utils/PathUtils";
import styles from "../style/home.module.css";

const HomePage = () => {
  return (
    <div>
      <HeaderView />
      <div className={styles.frontContent}>
        <Grid sx={{ flex: 1 }} container columns={10} spacing={5}>
          <Grid
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              justifyContent: "center",
            }}
            item
            size={4.5}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <label className={styles.frontHeaderLabel}>Digitalisation</label>
              <label className={styles.frontMessageLabel}>
                Elevate your business with our
              </label>
              <label className={styles.frontMessageLabel}>
                cutting-edge digital solution.
              </label>
            </div>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
            item
            size={5.5}
          >
            <img src={TechnologyImage} style={{ width: 700 }} alt="" />
          </Grid>
        </Grid>
        <div className={styles.startingContent}>
          <NavLink
            to={RegistrationPath.PROFILE}
            className={styles.startedButton}
          >
            Get Started
          </NavLink>
          <div className={styles.startingLabelContent}>
            <label className={styles.startingLabel}>
              no credit card needed
            </label>
            <div className={styles.startingBorder} />
            <label className={styles.startingLabel}>free plan option</label>
            <div className={styles.startingBorder} />
            <label className={styles.startingLabel}>earn credit points</label>
            <div className={styles.startingBorder} />
            <label className={styles.startingLabel}>enjoy discounts</label>
          </div>
        </div>
      </div>
      <div className={styles.efficiencyContent}>
        <label className={styles.efficiencyHeaderLabel}>
          Improve Business Efficiency
        </label>
        <label className={styles.efficiencyMessageLabel}>
          Checkout how the digital solution at shadwork will improve the
          business efficiency.
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85vw",
            marginTop: "10vh",
          }}
        >
          <div
            style={{ backgroundColor: "#7FC6E6" }}
            className={styles.efficiencyElement}
          >
            <div className={styles.efficiencyImageContent}>
              <AgilityImage width={"15vw"} />
            </div>
            <label className={styles.efficiencyElementMessage}>Enhance</label>
            <label className={styles.efficiencyElementMessage}>
              Agility and Flexibility
            </label>
            <div
              style={{ backgroundColor: "#81B1C6" }}
              className={styles.efficiencyElementFooter}
            >
              <NavLink
                to={
                  "https://info.shadwork.net/key-benefits/#enhance-agility-flexibility"
                }
                target="_blank"
                className={styles.efficiencyLearnMoreButton}
              >
                learn more
              </NavLink>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#AEE2CF" }}
            className={styles.efficiencyElement}
          >
            <div className={styles.efficiencyImageContent}>
              <BoostingImage width={"20vw"} />
            </div>
            <label className={styles.efficiencyElementMessage}>Boost</label>
            <label className={styles.efficiencyElementMessage}>
              Company Productivity
            </label>
            <div
              style={{ backgroundColor: "#ABC9BE" }}
              className={styles.efficiencyElementFooter}
            >
              <NavLink
                to={
                  "https://info.shadwork.net/key-benefits/#boost-company-productivity"
                }
                target="_blank"
                className={styles.efficiencyLearnMoreButton}
              >
                learn more
              </NavLink>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#5EA6C5" }}
            className={styles.efficiencyElement}
          >
            <div className={styles.efficiencyImageContent}>
              <ComplianceImage height={"19vh"} />
            </div>
            <label className={styles.efficiencyElementMessage}>Improve</label>
            <label className={styles.efficiencyElementMessage}>
              Traceability and Compliance
            </label>
            <div
              style={{ backgroundColor: "#85B0C3" }}
              className={styles.efficiencyElementFooter}
            >
              <NavLink
                to={
                  "https://info.shadwork.net/key-benefits/#improve-traceability-compliance"
                }
                target="_blank"
                className={styles.efficiencyLearnMoreButton}
              >
                learn more
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.benefitsContent}>
        <Grid
          container
          columns={10}
          sx={{
            width: "85vw",
            marginTop: "10vh",
            marginBottom: "10vh",
          }}
        >
          <Grid item size={10}>
            <label className={styles.benefitsHeaderLabel}>
              Shadwork key benefits
            </label>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "5vh",
            }}
          >
            <label className={styles.benefitsMessageLabel}>
              Improve cost efficiency
            </label>
            <ul className={styles.benefitsListLabel}>
              <li className={styles.benefitsListLabel}>
                reduce operational costs
              </li>
              <li className={styles.benefitsListLabel}>
                application cost-effectiveness
              </li>
            </ul>
          </Grid>
          <Grid
            item
            size={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "5vh",
            }}
          >
            <div style={{ border: "0.1vw solid white" }} />
          </Grid>
          <Grid
            item
            size={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              paddingTop: "5vh",
            }}
          >
            <div>
              <label className={styles.benefitsMessageLabel}>
                Personalised experience
              </label>
              <ul>
                <li className={styles.benefitsListLabel}>
                  improve customer experience
                </li>
                <li className={styles.benefitsListLabel}>
                  improve employee experience
                </li>
                <li className={styles.benefitsListLabel}>profile management</li>
              </ul>
            </div>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "end" }} item size={10}>
            <NavLink
              to={"https://info.shadwork.net/key-benefits/#b2b-saas-benefits"}
              target="_blank"
              className={styles.benefitsLearnMoreButton}
            >
              learn more
            </NavLink>
          </Grid>
        </Grid>
      </div>
      <div className={styles.advantagesContent}>
        <Grid container columns={10} sx={{ marginTop: "10vh", width: "85vw" }}>
          <Grid
            item
            size={3}
            sx={{ paddingTop: "5vh" }}
            className={styles.advantagesGridElement}
          >
            <div
              className={styles.advantageElement}
              style={{
                borderLeft: "1px solid #0D6986",
                borderTop: "1px solid #0D6986",
                borderBottom: "1px solid #0D6986",
                borderTopLeftRadius: 5,
                zIndex: -2,
              }}
            >
              <div className={styles.advantagePanel}>
                <div
                  className={styles.advantagePanelElement}
                  style={{ backgroundColor: "#0D6986" }}
                />
                <div
                  className={styles.advantagePanelElement}
                  style={{ backgroundColor: "#5EA6C5" }}
                />
                <div
                  className={styles.advantagePanelElement}
                  style={{ backgroundColor: "#7FC6E6" }}
                />
              </div>
              <div className={styles.advantageElementContent}>
                <label
                  style={{ height: "7vh" }}
                  className={styles.advantageElementMessage}
                >
                  Real-time Data
                </label>
                <RealTimeDataImage height={120} />
                <NavLink to={"#"} className={styles.advantageElementLink}>
                  real-time data insights and tracing
                </NavLink>
              </div>
            </div>
          </Grid>
          <Grid className={styles.advantagesGridElement} item size={4}>
            <div
              className={styles.advantageElement}
              style={{
                boxShadow: "1px 1px 10px 0px #808080",
                border: "0.1vh solid #0D6986",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                zIndex: -1,
              }}
            >
              <div className={styles.advantagePanel}>
                <div
                  className={styles.advantagePanelElement}
                  style={{ backgroundColor: "#0D6986" }}
                />
                <div
                  className={styles.advantagePanelElement}
                  style={{ backgroundColor: "#5EA6C5" }}
                />
                <div
                  className={styles.advantagePanelElement}
                  style={{ backgroundColor: "#7FC6E6" }}
                />
              </div>
              <div className={styles.advantageElementContent}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "end",
                    height: "12vh",
                  }}
                >
                  <label className={styles.advantageElementHeader}>
                    Competitive Advantage
                  </label>
                  <label
                    style={{ marginTop: "1vh" }}
                    className={styles.advantageElementMessage}
                  >
                    Automation
                  </label>
                </div>
                <AdvantageImage width={200} />
                <NavLink to={"#"} className={styles.advantageElementLink}>
                  automated ordering and fulfilment
                </NavLink>
              </div>
            </div>
          </Grid>
          <Grid
            item
            size={3}
            sx={{ paddingTop: "5vh" }}
            className={styles.advantagesGridElement}
          >
            <div
              className={styles.advantageElement}
              style={{
                borderRight: "1px solid #0D6986",
                borderTop: "1px solid #0D6986",
                borderBottom: "1px solid #0D6986",
                borderTopRightRadius: 5,
                zIndex: -2,
              }}
            >
              <div className={styles.advantagePanel} />
              <div className={styles.advantageElementContent}>
                <label
                  style={{ height: "7vh" }}
                  className={styles.advantageElementMessage}
                >
                  Privacy and Security
                </label>
                <SecurityImage width={200} />
                <NavLink to={"#"} className={styles.advantageElementLink}>
                  data privacy and security
                </NavLink>
              </div>
            </div>
          </Grid>
          <Grid className={styles.advantagesGridLearnMore} item size={12}>
            <NavLink
              to={
                "https://info.shadwork.net/key-benefits/#competitive-advantage"
              }
              target="_blank"
              className={styles.advantageLearnMoreButton}
            >
              learn more
            </NavLink>
          </Grid>
        </Grid>
      </div>
      <FooterView />
    </div>
  );
};

export default HomePage;
