import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Checkbox, ThemeProvider, FormControlLabel } from "@mui/material";
import NavigationControl from "../component/NavigationControl";
import {
  showDialog,
  showCallableDialog,
  DialogScope,
} from "../../common/dialog/GenericScopeDialog";
import {
  showLoading,
  hideLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { InputTheme } from "../../common/utils/ThemeUtils";
import { registerAccount } from "../../common/client/AccountClient";
import { ReactComponent as SubmitImage } from "../assets/submit_image.svg";
import styles from "../style/registration.module.css";

const SubmitForm = (props) => {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);

  return (
    <div className={styles.form}>
      <div className={styles.formGroup}>
        <div style={{ padding: "15vh 0" }} className={styles.inputGroup}>
          <ThemeProvider theme={InputTheme}>
            <label style={{ color: "#0d6986", textAlign: "center" }}>
              We will keep your credentials secure, and a temporary password
              will be issued to you via email for your first login.
            </label>
            <FormControlLabel
              required
              control={<Checkbox />}
              onChange={(event) => setAgree(event.target.checked)}
              label={
                <label style={{ color: "#0d6986" }}>
                  I Agree to the{" "}
                  <NavLink
                    target="_blank"
                    to={"https://info.shadwork.net/terms-conditions/"}
                    style={{ color: "#0d6986", fontWeight: "bold" }}
                  >
                    Terms and Conditions
                  </NavLink>
                </label>
              }
            />
          </ThemeProvider>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <SubmitImage height={180} />
        </div>
      </div>
      <NavigationControl
        onClick={(_, isSubmit) => {
          if (isSubmit) {
            if (agree) {
              showLoading();
              const account = {
                // the unique identifier for the account is mandatory but because actually there is no
                // policy defined about the safety keeping data will use a dummy value as account idno
                idno: "dummy",
                name: props.name,
                email: props.email,
                phone: props.phone,
                address: {
                  country: props.country,
                  city: props.city,
                  street: props.street,
                  zipCode: props.zipcode,
                },
                admin: {
                  email: props.username,
                  roles: ["ADMIN"],
                  firstName: props.firstname,
                  lastName: props.lastname,
                },
              };
              registerAccount(account)
                .then(() => {
                  showCallableDialog(
                    "Congratulations!",
                    `You have been successfully registered. Welcome to our community! We have sent a
                    password to your personal email address, use it and start exploring all the features
                    and benefits especially available to you.`,
                    DialogScope.CONGRATS
                  ).then(() => navigate("/home"));
                })
                .catch(() => {
                  showDialog(
                    "Error",
                    `We apologize for the inconvenience. An unexpected error has occurred while account
                    registering. Please contact us as email support@shadwork.com`,
                    DialogScope.ERROR
                  );
                })
                .finally(hideLoading);
            } else {
              showDialog(
                "Warning",
                "Before proceeding you must agree to the terms and conditions.",
                DialogScope.ERROR
              );
            }
          }
        }}
      />
    </div>
  );
};

export default SubmitForm;
